import React from "react";
import { useTheme } from "@pancakeswap/hooks";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  const { isDark } = useTheme();
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1626_1344)">
        <path
          d="M8.27355 3.99219C6.06381 3.99219 4.26611 5.79024 4.26611 7.99998C4.26611 10.2097 6.06381 12.0078 8.27355 12.0078C10.4829 12.0078 12.281 10.2101 12.281 7.99998C12.281 5.78988 10.4829 3.99219 8.27355 3.99219Z"
          fill={isDark ? "#545454" : "#FFB237"}
        />
        <path
          d="M8.27461 2.81453C7.84272 2.81453 7.49268 2.46448 7.49268 2.03295V0.781931C7.49268 0.350044 7.84272 0 8.27461 0C8.70649 0 9.05654 0.350044 9.05654 0.781931V2.03295C9.05654 2.46448 8.70614 2.81453 8.27461 2.81453Z"
          fill={isDark ? "#545454" : "#FFB237"}
        />
        <path
          d="M8.27461 13.1855C7.84272 13.1855 7.49268 13.5356 7.49268 13.9675V15.2181C7.49268 15.6504 7.84272 16.0004 8.27461 16.0004C8.70649 16.0004 9.05654 15.6504 9.05654 15.2181V13.9675C9.05654 13.5356 8.70614 13.1855 8.27461 13.1855Z"
          fill={isDark ? "#545454" : "#FFB237"}
        />
        <path
          d="M11.9402 4.33348C11.6352 4.02808 11.6352 3.53312 11.9402 3.22772L12.8249 2.34304C13.1299 2.038 13.6252 2.038 13.9306 2.34304C14.236 2.64845 14.236 3.14375 13.9306 3.4488L13.046 4.33348C12.7409 4.63888 12.246 4.63888 11.9402 4.33348Z"
          fill={isDark ? "#545454" : "#FFB237"}
        />
        <path
          d="M4.60656 11.6668C4.30116 11.3611 3.80621 11.3611 3.5008 11.6668L2.61613 12.5511C2.31108 12.8562 2.31072 13.3518 2.61613 13.6569C2.92153 13.9619 3.41684 13.9619 3.72188 13.6569L4.60656 12.7719C4.91196 12.4668 4.91196 11.9715 4.60656 11.6668Z"
          fill={isDark ? "#545454" : "#FFB237"}
        />
        <path
          d="M13.4575 7.9997C13.4575 7.56782 13.8076 7.21777 14.2395 7.21777H15.4905C15.9224 7.21777 16.2724 7.56782 16.2724 7.9997C16.2724 8.43159 15.9224 8.78128 15.4905 8.78128H14.2395C13.8076 8.78128 13.4575 8.43159 13.4575 7.9997Z"
          fill={isDark ? "#545454" : "#FFB237"}
        />
        <path
          d="M3.08845 7.9997C3.08845 7.56782 2.73841 7.21777 2.30652 7.21777H1.05586C0.62397 7.21777 0.273926 7.56782 0.273926 7.9997C0.273926 8.43159 0.62397 8.78128 1.05586 8.78128H2.30688C2.73841 8.78128 3.08845 8.43159 3.08845 7.9997Z"
          fill={isDark ? "#545454" : "#FFB237"}
        />
        <path
          d="M11.9405 11.6673C12.2459 11.3622 12.7412 11.3622 13.0462 11.6673L13.9309 12.5519C14.2363 12.8566 14.2363 13.3523 13.9309 13.6573C13.6255 13.9624 13.1306 13.9624 12.8252 13.6573L11.9405 12.7727C11.6351 12.4673 11.6351 11.9723 11.9405 11.6673Z"
          fill={isDark ? "#545454" : "#FFB237"}
        />
        <path
          d="M4.60669 4.33339C4.9121 4.02799 4.9121 3.53304 4.60669 3.22763L3.72202 2.34331C3.41661 2.03791 2.92166 2.03791 2.61626 2.34331C2.31086 2.64836 2.31086 3.14366 2.61626 3.44871L3.50094 4.33339C3.80634 4.63915 4.30129 4.63915 4.60669 4.33339Z"
          fill={isDark ? "#545454" : "#FFB237"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1626_1344">
          <rect width="16" height="16" fill="white" transform="translate(0.273926)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;

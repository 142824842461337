import React from "react";
import Link from "./Link";
import { LinkProps } from "./types";
import OpenNewIcon from "../Svg/Icons/OpenNew";
import BscScanIcon from "../Svg/Icons/BscScan";
import AptosIcon from "../Svg/Icons/Aptos";
import Svg from "../Svg/Svg";

const LinkExternal: React.FC<React.PropsWithChildren<LinkProps>> = ({
  children,
  isBscScan = false,
  isAptosScan = false,
  isContract = false,
  ...props
}) => {
  return (
    <Link external {...props}>
      {children}
      {isBscScan && <BscScanIcon color={props.color ? props.color : "primary"} ml="4px" />}
      {isAptosScan && <AptosIcon width="18" height="18" color={props.color ? props.color : "primary"} ml="4px" />}
      {!isBscScan && !isAptosScan && !isContract && <OpenNewIcon color={props.color ? props.color : "primary"} ml="4px" />}
      {isContract && (
        <Svg viewBox="0 0 20 20" ml="4px">
          <g clip-path="url(#clip0_1_1697)">
            <path
              d="M9.30554 5.83336C9.30554 5.45003 9.61665 5.13892 9.99999 5.13892H12.0833C12.4667 5.13892 12.7778 5.45003 12.7778 5.83336C12.7778 6.21669 12.4667 6.5278 12.0833 6.5278H9.99999C9.61665 6.5278 9.30554 6.21669 9.30554 5.83336Z"
              fill="#009BCC"
            />
            <path
              d="M16.9449 14.1671C16.9449 14.5504 16.6338 14.8615 16.2504 14.8615H14.1671C13.7838 14.8615 13.4727 14.5504 13.4727 14.1671C13.4727 13.7838 13.7838 13.4727 14.1671 13.4727H16.2504C16.6338 13.4727 16.9449 13.7838 16.9449 14.1671Z"
              fill="#009BCC"
            />
            <path
              d="M16.9446 8.61144C16.9446 8.99477 16.6334 9.30588 16.2501 9.30588H10.0001C9.61678 9.30588 9.30566 8.99477 9.30566 8.61144C9.30566 8.2281 9.61678 7.91699 10.0001 7.91699H16.2501C16.6334 7.91699 16.9446 8.2281 16.9446 8.61144Z"
              fill="#009BCC"
            />
            <path
              d="M16.9444 11.3889C16.9444 11.7722 16.6333 12.0833 16.25 12.0833H9.99999C9.61665 12.0833 9.30554 11.7722 9.30554 11.3889C9.30554 11.0056 9.61665 10.6945 9.99999 10.6945H16.25C16.6333 10.6945 16.9444 11.0056 16.9444 11.3889Z"
              fill="#009BCC"
            />
            <path
              d="M4.44401 13.4718C4.20026 13.4718 3.97387 13.5996 3.84887 13.8086L2.77595 15.5968C1.47943 15.3336 0.277344 16.3301 0.277344 17.6385C0.277344 18.7871 1.21207 19.7218 2.36068 19.7218C3.50929 19.7218 4.44401 18.7871 4.44401 17.6385C4.44401 17.1364 4.2579 16.6815 3.96137 16.3211L4.83707 14.8607H6.52734V17.6385C6.52734 18.0218 6.83846 18.3329 7.22179 18.3329H19.0273C19.4107 18.3329 19.7218 18.0218 19.7218 17.6385V5.8329C19.7218 5.64887 19.6468 5.4704 19.5183 5.34193L16.0468 1.8704C15.9176 1.74123 15.7392 1.66623 15.5551 1.66623H7.22179C6.83846 1.66623 6.52734 1.97734 6.52734 2.36068V5.13845H4.83707L3.96137 3.67804C4.2579 3.31762 4.44401 2.86276 4.44401 2.36068C4.44401 1.21207 3.50929 0.277344 2.36068 0.277344C1.21207 0.277344 0.277344 1.21207 0.277344 2.36068C0.277344 3.66832 1.47873 4.66554 2.77595 4.40234L3.84887 6.19054C3.97387 6.39957 4.20026 6.52734 4.44401 6.52734H6.52734V9.30512H4.31623C4.02873 8.49887 3.26484 7.91623 2.36068 7.91623C1.21207 7.91623 0.277344 8.85096 0.277344 9.99957C0.277344 11.1482 1.21207 12.0829 2.36068 12.0829C3.26484 12.0829 4.02873 11.5003 4.31623 10.694H6.52734V13.4718H4.44401ZM2.36068 18.3329C1.97804 18.3329 1.66623 18.0211 1.66623 17.6385C1.66623 17.2558 1.97804 16.944 2.36068 16.944C2.74332 16.944 3.05512 17.2558 3.05512 17.6385C3.05512 18.0211 2.74332 18.3329 2.36068 18.3329ZM16.2496 4.03707L17.351 5.13845H16.2496V4.03707ZM7.91623 3.05512H14.8607V5.8329C14.8607 6.21623 15.1718 6.52734 15.5551 6.52734H18.3329V16.944H7.91623V3.05512ZM2.36068 3.05512C1.97804 3.05512 1.66623 2.74332 1.66623 2.36068C1.66623 1.97804 1.97804 1.66623 2.36068 1.66623C2.74332 1.66623 3.05512 1.97804 3.05512 2.36068C3.05512 2.74332 2.74332 3.05512 2.36068 3.05512ZM2.36068 10.694C1.97804 10.694 1.66623 10.3822 1.66623 9.99957C1.66623 9.61693 1.97804 9.30512 2.36068 9.30512C2.74332 9.30512 3.05512 9.61693 3.05512 9.99957C3.05512 10.3822 2.74332 10.694 2.36068 10.694Z"
              fill="#009BCC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_1697">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </Svg>
      )}
    </Link>
  );
};

export default LinkExternal;
